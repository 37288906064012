import React from "react"

//  Icons
import Logo from "../../images/icons/logo.svg"
import BuyToLet from "../../images/icons/buy-to-let.svg"
import CareHome from "../../images/icons/care-home.svg"
import Hotel from "../../images/icons/hotel.svg"
import Student from "../../images/icons/student.svg"

export const Icon = ({ name }) => {
  if (!name) return null

  switch (name.toLowerCase()) {
    //    Sectors
    case "buy-to-let":
      return <BuyToLet />
    case "care-home":
      return <CareHome />
    case "hotel-room":
      return <Hotel />
    case "student-property":
      return <Student />
    case "logo":
      return <Logo />

    default:
      console.log(
        `Can't find icon for "${name.toLowerCase()}" - Using default logo`
      )
      return <Logo />
  }
}
