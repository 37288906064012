import React from "react"
import styled from "styled-components"
import { CallMeBack } from "./CallMeBack"
import { GetDetails } from "../Misc/GetDetails"

export const GetDetailsCTABlock = () => (
  <div className="bg-primary text-white text-center my-4">
    <GetDetails size="sm" />
  </div>
)

export const ArticleCTABlock = () => {
  return (
    <div className="bg-primary text-light text-center my-5 p-3">
      <p className="m-0">
        <span className="mr-4">Start your property journey...</span>
        <CallMeBack />
      </p>
    </div>
  )
}

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    width: 25px;
    height: 25px;
    transform: rotate(-90deg);
    fill: ${({ theme }) => theme.secondary};
  }

  p {
    display: inline-block;
    margin: 0 0 0 15px;
  }
`

export const RelatedArticlesCTA = () => {
  return (
    <Wrapper>
      <a href={`#related-articles`}>
        <svg viewBox="0 0 31 29">
          <path d="M15.5,29L0,0h31L15.5,29z" />
        </svg>
        <p>Related Articles</p>
      </a>
    </Wrapper>
  )
}
export const RecommendedPropertiesCTA = () => {
  return (
    <Wrapper>
      <a href={`#recommended-properties`}>
        <svg viewBox="0 0 31 29">
          <path d="M15.5,29L0,0h31L15.5,29z" />
        </svg>
        <p>Recommended Properties</p>
      </a>
    </Wrapper>
  )
}
