import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { mediaBreakpoint } from "../../utils/breakpoints"

const StyledImageDiv = styled.div`
  width: ${props => props.calculatedWidth}%;

  @media ${mediaBreakpoint.down.lg} {
    width: 100%;
  }
`

const ContentPositionBottom = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`

export const PropertyPhotos = ({ photos = [], curveBottom }) => {
  if (!photos || photos.length === 0) {
    return null
  }
  return (
    <div className="d-flex flex-wrap position-relative">
      {photos.map(photo => {
        return (
          <StyledImageDiv key={photo.id} calculatedWidth={100 / photos.length}>
            <Img
              fluid={photo.fluid}
              style={{ maxHeight: "480px" }}
              alt={photo.title}
            />
          </StyledImageDiv>
        )
      })}
      <ContentPositionBottom>{curveBottom}</ContentPositionBottom>
    </div>
  )
}
