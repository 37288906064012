import React from "react"
import { StaticQuery, graphql } from "gatsby"

import slugify from "slugify"

import GuideForm from "../../Forms/GuideForm"

import { ModalPopUp } from "../../Modal/ModalPopUp"
import { GuideGraphic } from "../../Guides/GuideTiles"

export const MarkdownGuideForm = ({ children, id }) => {
  return (
    <StaticQuery
      query={graphql`
        query GuideFormQuery {
          allContentfulGuide {
            nodes {
              ...GuideFragment
            }
          }
        }
      `}
      render={({ allContentfulGuide }) => {
        const guides = allContentfulGuide.nodes
        const selectedGuide = guides.find(guide => guide.contentful_id === id)

        if (!selectedGuide) return <>Error</>

        const guideName = `${slugify(
          selectedGuide.title
        ).toLowerCase()}-download`
        return (
          <ModalPopUp trigger={<>{children}</>}>
            <GuideForm
              title={selectedGuide.title}
              intro={selectedGuide.introduction}
              name={guideName}
              graphic={
                <GuideGraphic
                  title={selectedGuide.title}
                  sector={selectedGuide.sector}
                />
              }
              successPage={guideName}
            />
          </ModalPopUp>
        )
      }}
    />
  )
}
