import React from "react"
import { Row, Col, Button } from "react-bootstrap"
import Img from "gatsby-image"
import { Icon } from "../IconFactory/Icons"
import styled from "styled-components"
// import { Link } from "gatsby"
import slugify from "slugify"

import { ModalPopUp } from "../Modal/ModalPopUp"
import GuideForm from "../Forms/GuideForm"

const StyledGuideTileWrapper = styled.a`
  display: block;
  width: 100%;
  background-color: #fff;
  border-bottom: 10px solid ${({ theme }) => theme.primary};
  margin-bottom: 2rem;
  color: inherit;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-decoration: none;
    color: inherit;
    transform: translateY(-15px);
  }
`

const StyledGuideTile = styled.div`
  display: flex;
  width: 100%;
  font-size: 1rem;
`

const StyledTopWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.primary};
  cursor: pointer;
`
const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 50px;
    width: 50px;
    margin: 15px;
  }

  :not(.bg-primary) {
    svg path {
      fill: #fff;
    }
  }
`

const StyledGuideTileTitle = styled.p`
  margin: 1rem;
`

export const GuideTiles = ({ guides = [] }) => {
  if (guides.length < 1) return null

  return (
    <div>
      <Row>
        {guides.map(guide => (
          <Col md={6} lg={3} key={guide.id} className="d-flex">
            <GuideTile guide={guide} />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export const GuideGraphic = ({ title, sector }) => (
  <StyledGuideTile>
    <StyledIconWrapper className={`bg-${(sector && sector.key) || "primary"}`}>
      {sector ? <Icon name={sector.key} /> : <Icon name="logo" />}
    </StyledIconWrapper>

    <StyledGuideTileTitle>{title}</StyledGuideTileTitle>
  </StyledGuideTile>
)

const GuideImg = ({ guide }) => (
  <StyledTopWrapper>
    <Img fluid={guide.image.fluid} alt={guide.image.title} />
    <GuideGraphic title={guide.title} sector={guide.sector} />
  </StyledTopWrapper>
)

export const GuideTile = ({ guide }) => {
  if (!guide) return null

  const guideName = `${slugify(guide.title).toLowerCase()}-download`

  return (
    <StyledGuideTileWrapper>
      {/* There's another instance of this further down with another trigger*/}
      <ModalPopUp trigger={<GuideImg guide={guide} />}>
        <GuideForm
          intro={guide.introduction}
          title={guide.title}
          name={guideName}
          graphic={<GuideGraphic title={guide.title} sector={guide.sector} />}
          successPage={guideName}
        />
      </ModalPopUp>

      <div>
        <Row>
          <Col>
            <div className="p-3">
              <p>{guide.introduction}</p>
              <ModalPopUp
                trigger={
                  <Button variant="secondary" className="d-block mx-auto">
                    Download
                  </Button>
                }
              >
                <GuideForm
                  intro={guide.introduction}
                  title={guide.title}
                  name={guideName}
                  graphic={
                    <GuideGraphic title={guide.title} sector={guide.sector} />
                  }
                  successPage={guideName}
                />
              </ModalPopUp>
            </div>
          </Col>
        </Row>
      </div>
    </StyledGuideTileWrapper>
  )
}
