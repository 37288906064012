import React, { useState } from "react"
import {
  Accordion,
  useAccordionToggle,
  Row,
  Container,
  Col,
} from "react-bootstrap"
import styled, { keyframes } from "styled-components"

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(25%);
  }
  40% {
    transform: translateY(-25%);
  }
  60% {
    transform: translateY(-15%);
  }
`

const StyledAccordionToggle = styled.button`

  /* Normalize Button */
  width: 100%;
  border: 0;
  border-radius: 0;
  outline: 0 !important;
  /* Style */
  padding:  0;
  background:none;
  

  /* Icon */
  svg.triangle {


    display: block;
    margin: auto;
    width: 25px;
    height: 25px;
    transition: all 0.25s ease-in-out;
    fill: ${({ theme }) => theme.secondary};
  }

  &:hover {
    /* background: ${({ theme }) => theme.secondary}; */

    svg.triangle {
      fill: ${({ theme }) => theme.primary};
    }
  }

  &.open {
   
    svg.triangle {
      transform: rotate(180deg);
    }
  }
  
  &:not(.open) {
    svg.triangle {
      animation: ${bounce} 2s linear infinite;
    }
  }


`

const StyledAccordionBody = styled.div`
  padding: 30px 0;

  img {
    max-width: 100%;
  }
`

function CustomToggle({ children, eventKey, defaultOpen }) {
  const [open, setOpen] = useState(defaultOpen)

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setOpen(!open)
  })

  return (
    <StyledAccordionToggle
      className={open ? "open" : null}
      onClick={decoratedOnClick}
      aria-label="Toggle Accordion"
    >
      {children}
    </StyledAccordionToggle>
  )
}

export const AccordionSquared = ({
  defaultOpen = false,
  variant = "white",
  title = "Need a title",
  titleMobile = title,
  introduction,
  children,
}) => {
  return (
    <div>
      <Accordion defaultActiveKey={defaultOpen ? "0" : null}>
        <CustomToggle defaultOpen={defaultOpen} eventKey="0">
          <div
            className={`bg-${variant} ${
              variant !== "white" ? "text-light" : "text-primary"
            } text-center`}
          >
            <div className="m-0 py-4">
              <h3 className="d-md-none">{titleMobile}</h3>
              <h3 className="d-none d-md-block">{title}</h3>
              <svg className="triangle" viewBox="0 0 31 29">
                <path d="M15.5,29L0,0h31L15.5,29z" />
              </svg>
            </div>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey="0">
          <div className="bg-light">
            <StyledAccordionBody>
              <Container>
                <Row>
                  <Col md={9} className="mx-auto">
                    {children}
                  </Col>
                </Row>
              </Container>
            </StyledAccordionBody>
          </div>
        </Accordion.Collapse>
      </Accordion>
    </div>
  )
}
