import React from "react"
import rehypeReact from "rehype-react"
import styled from "styled-components"

import {
  ArticleCTABlock,
  RelatedArticlesCTA,
  RecommendedPropertiesCTA,
} from "../Misc/MarkdownCTABlocks"

import { MarkdownGuideForm } from "./Forms/MarkdownGuideForm"

import {
  PropertyEnquiry,
  GetPropertyDetails,
  CallBack,
} from "../Misc/MarkdownLinks"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    "related-articles-block": RelatedArticlesCTA,
    "recommended-properties-block": RecommendedPropertiesCTA,
    "guide-form": MarkdownGuideForm,
    "property-enquiry-form": PropertyEnquiry,
    "get-property-details-form": GetPropertyDetails,
    "call-back-form": CallBack,
    hr: ArticleCTABlock,
  },
}).Compiler

const MarkdownWrapper = styled.div`
  img {
    max-width: 100%;
  }
`

export const MarkdownCommon = ({ body, ...props }) => {
  if (!body || !body.childMarkdownRemark || !body.childMarkdownRemark.htmlAst)
    return null

  return (
    <MarkdownWrapper {...props}>
      {renderAst(body.childMarkdownRemark.htmlAst)}
    </MarkdownWrapper>
  )
}
