import React from "react"
import styled from "styled-components"

import Logo from "../../images/icons/logo.svg"

const StyledHr = styled.hr`
  background-color: ${({ theme }) => theme.secondary};
  height: 8px;
  width: 50px;
  border-radius: 5px;
  border: 0;
`

export const HR = props => {
  return <StyledHr {...props} />
}

//  Logo Seperator

const StyledLogoSeperator = styled.div`
  background-color: ${({ theme }) => theme.primary};
  display: flex;
  justify-content: center;
  padding: 15px;
`
const StyledLogo = styled(Logo)`
  width: 50px;

  path {
    fill: #fff;
  }
`

export const LogoSeperator = props => {
  return (
    <StyledLogoSeperator>
      <div>
        <StyledLogo />
      </div>
    </StyledLogoSeperator>
  )
}
