import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import { HeroCTAForm } from "../../components/Hero/HeroCTAForm"
import { Container, Row, Col, Button } from "react-bootstrap"
import { HR } from "../../components/Typography/Seperators"
import { CallMeBackFull } from "../../components/Misc/CallMeBackFull"
import { PropertyPhotos } from "../../components/Property/PropertyPhotos"
import { AccordionSquared } from "../../components/Accordion/AccordionSquared"
import { FaCircle } from "react-icons/fa"
import { SqueezePageContext } from "./SqueezePageContext"
import { MarkdownCommon } from "../../components/Markdown/MarkdownCommon"
import GuideForm from "../../components/Forms/GuideForm"
import { ModalPopUp } from "../../components/Modal/ModalPopUp"
import { FeatureImage } from "../../components/Feature/FeatureImage"
import {
  CurveCarveTop,
  CurveCarveBottom,
  CurveBulgeBottom,
} from "../../components/Curves/Curve"

import slugify from "@sindresorhus/slugify"


const SectorSqueezeSite = ({
  location,
  pageContext,
  data: { contentfulSqueezeSiteSectorGuide: squeezeSite },
}) => {
  if (!squeezeSite) {
    return (
      <Layout>
        <Container className="text-center">
          <Row>
            <Col>
              <p className="lead">
                Error, can't find Squeeze site Id: {pageContext.contentful_id}
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }

  
  const { guide } = squeezeSite
  const sector = squeezeSite.sector

  const guideDownloadURL = `${slugify(
    guide.title
  ).toLowerCase()}-download?squeezeSectorGuide=${squeezeSite.contentful_id}`

  const guideForm = (
    <GuideForm
      title={`${guide.title}`}
      name={`${squeezeSite.title} squeeze sector guide form`}
      successPage={guideDownloadURL}
    />
  )

  const menuItems = [
    "Home",
    squeezeSite.introductionMenuTitle,
    squeezeSite.mainContentMenuTitle,
    "When to invest",
    "How to invest",
    "Contact",
  ]

  return (
    <SqueezePageContext location={location} menuItems={menuItems}>
      <Layout>
        <SEO title={squeezeSite.seoTitle} />
        <div id="home" />

        <HeroCTAForm
          fluid={sector.heroPhoto.fluid}
          variant={sector.key}
          curveTop={<CurveBulgeBottom variant="white" />}
          curveBottom={<CurveCarveTop variant="primary" />}
          form={guideForm}
        >
          <div className="sector">{sector.subtitle}</div>
          <h1 className="name">{squeezeSite.title}</h1>
          <div className="price">{squeezeSite.subtitle}</div>
          <div className="call">
            <a href="tel:+44 203 709 4275" target="_blank" rel="noreferrer">
              Call: +44 (0) 203 709 4275
            </a>
          </div>
          <div className="features">
            {squeezeSite.bullets.map((bullet, i) => (
              <div key={bullet + i} className="feature-item">
                <FaCircle className="text-secondary" />
                <div>{bullet}</div>
              </div>
            ))}
          </div>
        </HeroCTAForm>

        <div id={slugify(squeezeSite.introductionMenuTitle)} />
        <div className="bg-primary text-white text-center py-5">
          <Container>
            <Row>
              <Col md={8} className="mx-auto">
                <h2>{squeezeSite.introductionTitle}</h2>
                <HR />
                <MarkdownCommon
                  className="lead"
                  body={squeezeSite.introductionBody}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ModalPopUp
                  trigger={
                    <Button variant="secondary" className="d-block mx-auto">
                      Download Guide
                    </Button>
                  }
                >
                  {guideForm}
                </ModalPopUp>
              </Col>
            </Row>
          </Container>
        </div>
        <CurveCarveBottom variant="primary" />

        <div id="key-figures">
          <Container className="py-4 text-center">
            <Row>
              <Col>
                <h2 className="mb-5">Key Figures</h2>
              </Col>
            </Row>
            <Row>
              {squeezeSite.features &&
                squeezeSite.features.map(feature => {
                  return <FeatureImage key={feature.id} feature={feature} />
                })}
            </Row>
          </Container>
        </div>

        <CurveCarveTop variant="light" />
        <div id={slugify(squeezeSite.mainContentMenuTitle)} />
        <div className="bg-light py-5">
          <Container>
            <Row>
              <Col>
                <h2 className="mb-5 text-center">
                  {squeezeSite.mainContentTitle}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <MarkdownCommon body={squeezeSite.mainContentBody} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ModalPopUp
                  trigger={
                    <Button variant="secondary" className="d-block mx-auto">
                      Download Guide
                    </Button>
                  }
                >
                  {guideForm}
                </ModalPopUp>
              </Col>
            </Row>
          </Container>
        </div>

        <div id="photos" />
        <PropertyPhotos photos={squeezeSite.photos} />

        <div id="when-to-invest" />
        <div>
          {squeezeSite.accordions && squeezeSite.accordions.map(accordion => (
            <div>
            {accordion.title.toLowerCase().startsWith("how") && <div id="how-to-invest"></div>}
            <AccordionSquared
              defaultOpen
              key={accordion.id}
              title={accordion.title}
              variant={squeezeSite.guide.sector && squeezeSite.guide.sector.key}
            >
              <MarkdownCommon body={accordion.body} />
            </AccordionSquared>
            </div>
          ))}
        </div>

        <div id="contact" />
        <div className="bg-primary pt-4">
          <ModalPopUp
            trigger={
              <Button variant="secondary" className="d-block mx-auto">
                Download Guide
              </Button>
            }
          >
            {guideForm}
          </ModalPopUp>
        </div>
        <CurveBulgeBottom variant="primary" />
        <CallMeBackFull />
      </Layout>
    </SqueezePageContext>
  )
}

export const query = graphql`
  query SectorSqueezeSiteQuery($contentful_id: String) {
    contentfulSqueezeSiteSectorGuide(contentful_id: { eq: $contentful_id }) {
      contentful_id
      seoTitle
      seoDescription {
        seoDescription
      }
      title
      subtitle
      bullets
      guide {
        title
      }
      sector {
        subtitle
        key
        heroPhoto {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        fundamentals {
          ...FeatureFragment
        }
      }
      introductionMenuTitle
      introductionTitle
      introductionBody {
        childMarkdownRemark {
          htmlAst
        }
      }
      features {
        ...FeatureFragment
      }
      mainContentMenuTitle
      mainContentTitle
      mainContentBody {
        childMarkdownRemark {
          htmlAst
        }
      }
      photos {
        id
        title
        fluid(maxWidth: 500, maxHeight: 500) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      accordions {
        ...AccordionFragment
      }
    }
  }
`

export default SectorSqueezeSite
