import React from "react"

import { ModalPopUp } from "../Modal/ModalPopUp"

import PropertyEnquiryForm from "../Forms/PropertyEnquiryForm"
import CallBackForm from "../Forms/CallBackForm"
import GetPropertyDetailsForm from "../Forms/GetPropertyDetailsForm"

export const PropertyEnquiry = ({ children }) => (
  <ModalPopUp trigger={<>{children}</>}>
    <PropertyEnquiryForm />
  </ModalPopUp>
)

export const CallBack = ({ children }) => (
  <ModalPopUp trigger={<>{children}</>}>
    <CallBackForm showText />
  </ModalPopUp>
)

export const GetPropertyDetails = ({ children }) => (
  <ModalPopUp trigger={<>{children}</>}>
    <GetPropertyDetailsForm />
  </ModalPopUp>
)
