/* eslint jsx-a11y/control-has-associated-label: "off", curly: "error" */

import React, { Fragment } from "react"

import CommonFields from "./Inputs/CommonFields"
import CurrentPage from "./Inputs/CurrentPage"
import NetlifyForm from "./Template/NetlifyForm"

const PropertyEnquiryForm = () => (
  <Fragment>
    <h2 className="h4 text-center">Enquire about this property today</h2>

    <NetlifyForm name="property-enquiry" btnText="Request Information">
      <CommonFields />

      <CurrentPage />
    </NetlifyForm>

    <div className="text-center small">
      <p>
        By filling in your details you acknowledge that you will be joining our
        mailing list to receive property investment news and relevant property
        investments.
      </p>
    </div>
  </Fragment>
)

export default PropertyEnquiryForm
