import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { Container, Row, Col } from "react-bootstrap"

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
`

const ContentPositionCenter = styled.div`
  height: 100%;
  width: 100%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  padding-bottom: 30px;

  > div {
    padding: 15px;
  }

  .sector {
    background-color: ${({ theme, variant }) => theme[variant]};
  }

  .name {
    background-color: #fff;
    color: ${({ theme }) => theme.primary};
    border-bottom: 10px solid ${({ theme, variant }) => theme[variant]};
    padding: 15px;
  }

  .price {
    background-color: ${({ theme }) => theme.primary};
  }
  .call {
    background-color: ${({ theme }) => theme.secondary};

    a {
      color: #fff;
    }
  }
  .features {
    background-color: ${({ theme }) => theme.primary};

    .feature-item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      svg {
        width: 5px;
        margin-right: 15px;
      }
    }
  }
`

const ContentPositionBottom = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`

const FormWrapper = styled.div`
  border-top: 75px solid ${({ theme }) => theme.primary};
  background: white;
  padding: 3rem;
`

export const HeroCTAForm = ({
  fluid,
  variant,
  children,
  curveTop,
  curveBottom,
  form,
}) => {
  return (
    <StyledBackgroundSection fluid={fluid}>
      {curveTop}
      <ContentPositionCenter>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <ContentWrapper variant={variant}>{children}</ContentWrapper>
            </Col>
            {form && (
              <Col xs={12} md={6}>
                <FormWrapper>{form}</FormWrapper>
              </Col>
            )}
          </Row>
        </Container>
      </ContentPositionCenter>

      <ContentPositionBottom>{curveBottom}</ContentPositionBottom>
    </StyledBackgroundSection>
  )
}
