import React from "react"
import { Col } from "react-bootstrap"
import Img from "gatsby-image"

export const FeatureImage = ({ feature }) => {
  return (
    <Col key={feature.id} md={4} className="mx-auto">
      <div className="my-5">
        {feature.image && feature.image.fixed && (
          <Img fixed={feature.image.fixed} alt={feature.image.title} />
        )}
      </div>
      <h3>{feature.title}</h3>
    </Col>
  )
}
