import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { CallMeBack } from "./CallMeBack"

export const CallMeBackFull = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "sales-staff/arran.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="py-3">
      <Container>
        <Row>
          <Col md={9} className="d-flex align-items-center">
            <div>
              <p className="lead">“We are your partner in prosperity”</p>
              <p>
                If you are considering buying investment property, it starts
                with a conversation. If we understand what you are looking for
                we are in a better position to help you achieve your goals and
                save you time by recommending the most suitable property.
              </p>
              <p>Request a call back</p>
            </div>
          </Col>

          <Col className="text-center">
            <Img
              className="d-block mx-auto my-4 rounded-circle"
              fixed={data.placeholderImage.childImageSharp.fixed}
              alt="Arran Kerkvliet - Head Shot"
            />

            <CallMeBack btnLg />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
