import React, { Fragment } from "react"

import NetlifyForm from "./Template/NetlifyForm"
import CommonFields from "./Inputs/CommonFields"
import CurrentPage from "./Inputs/CurrentPage"

const GuideForm = ({ graphic, title, name, intro, successPage }) => (
  <Fragment>
    <div className="text-center">
      <h2 className="h4">{`Download your free ${title} today`}</h2>
      {intro && <p>{intro}</p>}
    </div>
    {graphic && (
      <div className="w-100 mx-auto border border-primary mb-3">{graphic}</div>
    )}
    <NetlifyForm
      successPage={successPage}
      name={name}
      btnText="Request Information"
    >
      <CommonFields />
      <CurrentPage />
    </NetlifyForm>
    <div className="text-center small">
      <p>
        By filling in your details you acknowledge that you will be joining our
        mailing list to receive property investment news and relevant property
        investments.
      </p>
    </div>
  </Fragment>
)

export default GuideForm
